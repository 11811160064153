// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  i18n: {
    supportedLanguages: ['en', 'en-US'],
    defaultLanguage: 'en-US'
  },
  env: 'production',
  authEnv: 'production',
  name: 'production',
  appName: 'iot-device-manager',
  auth: {
    clientId: 'rrro5xwXF2NXMLnH8J1z8OGgBiKdxRzo',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    redirectUri: window.location.origin,
    errorPath: '/error',
    applicationId: '80ba7ce2-616e-4aab-921b-648bd28d3646',
    defaultZonarRole: '11933f97-0aab-476a-b9d0-009064e9bbe9',
    useRefreshTokens: true
  },
  datadog: {
    applicationId: 'c6409f95-666d-4cb9-90fb-0ab165667c35',
    clientToken: 'pubb6e17de02a7376d7a8f0565ead22a33b',
    site: 'us5.datadoghq.com'
  },
  slackurl:
    'https://join.slack.com/share/enQtNDM3NTQxNjg3ODE0Ny02ZTQ2NWIyMjA0ZTZhNjUwZjNkY2JiZWMwMTNiZTVmOTc2ZWMyZGZkZTg0MTlmNzE2YzA1YWQ4NzFlOTBiOGMy',
  serviceapi: 'https://api.zonarsystems.net/core/v1beta2',
  iotcoreapi: 'https://api.zonarsystems.net/iot/v1',
  iotprovisioningapi: 'https://api.zonarsystems.net/iot/v1beta1',
  iotactivecompaniesapi:
    'https://api.zonarsystems.net/iot/v1beta1/active-companies',
  packageapi: 'https://api.zonarsystems.net/iot/v1beta1/package-configs',
  validationapi:
    'https://us-central1-cl-production-635f.cloudfunctions.net/iot-device-config-status-prod',
  publishapi:
    'https://us-west2-cl-production-635f.cloudfunctions.net/iot-config-publish-service-prod',
  datasetapi: 'https://api.zonarsystems.net/iot/v1beta1',
  triggerapi: 'https://api.zonarsystems.net/iot/v1beta1',
  lastRebootAPI: 'https://api.zonarsystems.net/iot/v1',
  entityAssets: 'https://api.zonarsystems.net/core/v1beta2/assets/',
  entityDevices:
    'https://api.zonarsystems.net/core/v1beta2/devices?page=1&per_page=1000&status=ACTIVE',
  entityDevicesAssociations: 'https://api.zonarsystems.net/iot/v1beta1',
  entityMapping:
    'https://iot-device-configuration-api.production.zonarsystems.net/api/v1beta1/device-associations?page=1&per_page=1000',
  refreshConfigStatus:
    'https://us-central1-cl-production-635f.cloudfunctions.net/iot-device-config-status-prod',
  pgnsapi: 'https://api.zonarsystems.net/iot/v1beta1/pgns',
  userId: 'https://api.zonarsystems.net/core/v1/users',
  roles: 'https://api.zonarsystems.net/core/v1beta2/userprofiles?userId=',
  httpInterceptor: {
    allowedList: [
      `https://api.zonarsystems.net/core/v1beta2/*`,
      `https://iot-core-services-apis.production.zonarsystems.net/api/v1/*`,
      'https://api-dev.zonarsystems.net/*',
      'https://api-qa.zonarsystems.net/*',
      'https://api.zonarsystems.net/*',
      'https://zonar-nonprod-dev.apigee.net/*',
      'https://device-api.production.zonarsystems.net/*',
      'https://iot-device-configuration-api.dev.zonarsystems.net/*',
      'https://iot-device-configuration-api.prod.zonarsystems.net/*',
      'https://us-central1-cl-production-635f.cloudfunctions.net/*',
      'https://us-west2-cl-production-635f.cloudfunctions.net/*',
      'https://iot-device-configuration-api.production.zonarsystems.net/api/v1beta1/device-associations',
      'https://rum.browser-intake-us5.datadoghq.com/*',
      'https://logs.browser-intake-us5.datadoghq.com/*',
      'https://iot-active-companies-api.production.zonarsystems.net/*',
      'https://iot-asset-trigger-mappings-api.production.zonarsystems.net/*'
    ]
  },
  production: true,
  stage: false,
  whitelistModification:
    'https://api.zonarsystems.net/iot/v1beta1/active-companies/',
  nameSearch: 'https://api.zonarsystems.net/core/v1beta2/companies?q=name:',
  accountCodeSearch:
    'https://api.zonarsystems.net/core/v1beta2/divisions?q=legacyAccountCode:',
  assetTriggerMappingsSearch:
    'https://iot-asset-trigger-mappings-api.production.zonarsystems.net/api/v1beta1/assettriggermappings',
  assetTriggerMappingsUpdate:
    'https://iot-asset-trigger-mappings-api.production.zonarsystems.net/api/v1beta1/assettriggermappings',
  assetTriggerMappingsCreate:
    'https://iot-asset-trigger-mappings-api.production.zonarsystems.net/api/v1beta1/assettriggermappings'
};
